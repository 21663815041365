import React from "react"
import { FlexGrid, FlexGridCell } from "../components/flex-grid"

const Card = props => {
  return (
    <div className="bg-indigo-300 rounded-lg overflow-hidden shadow-lg w-full h-full border border-teal-500">
      {props.children}
    </div>
  )
}

const GridPage = () => {
  return (
    <FlexGrid>
      <FlexGridCell>
        <Card>
          <p>
            1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
            feugiat dapibus enim, ac scelerisque sapien volutpat id. Curabitur
            at elementum nisl, in viverra enim.
          </p>
        </Card>
      </FlexGridCell>
      <FlexGridCell>
        <Card>
          <p>2. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Card>
      </FlexGridCell>
      <FlexGridCell>
        <Card>
          <p>
            3. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
            feugiat dapibus enim, ac scelerisque sapien volutpat id. Curabitur
            at elementum nisl, in viverra enim. Phasellus sed ante eu turpis
            imperdiet auctor pretium eu nisl. Integer ut iaculis sapien. Ut
            auctor lacus mauris, nec eleifend purus elementum eget. Fusce vel
            egestas lectus. Aenean sed tincidunt nisl,
          </p>
        </Card>
      </FlexGridCell>
      <FlexGridCell>
        <Card>
          <p>4. Lorem ipsum dolor sit amet, at nulla.</p>
        </Card>
      </FlexGridCell>
      <FlexGridCell>
        <Card>
          <p>
            5. Integer ut iaculis sapien. Ut auctor lacus mauris, nec eleifend
            purus elementum eget. Fusce vel egestas lectus. Aenean sed tincidunt
            nisl, quis fringilla leo. Nam ut lectus in sapien ultricies
            ultrices.
          </p>
        </Card>
      </FlexGridCell>
      <FlexGridCell>
        <Card>
          <p>
            6. Aliquam erat volutpat. Pellentesque augue neque, pulvinar nec
            vulputate non, sollicitudin at nulla.
          </p>
        </Card>
      </FlexGridCell>
      <FlexGridCell>
        <Card>
          <p>
            7. Aenean sed tincidunt nisl, quis fringilla leo. Nam ut lectus in
            sapien ultricies ultrices. Aliquam erat volutpat. Pellentesque augue
            neque, pulvinar nec vulputate non, sollicitudin at nulla.
          </p>
        </Card>
      </FlexGridCell>
      <FlexGridCell>
        <Card>
          <p>
            8. Aenean sed tincidunt nisl, quis fringilla leo. Nam ut lectus in
            sapien ultricies ultrices. Aliquam erat volutpat. Pellentesque augue
            neque, pulvinar nec
          </p>
        </Card>
      </FlexGridCell>
      <FlexGridCell>
        <Card>
          <p>
            9. Aenean sed tincidunt nisl, quis fringilla leo. Aliquam erat
            volutpat. Pellentesque augue neque, pulvinar nec
          </p>
        </Card>
      </FlexGridCell>
      <FlexGridCell>
        <Card>
          <p>
            10. Aenean sed tincidunt nisl, quis fringilla leo. Nam ut lectus in
            sapien ultricies ultrices. Aliquam erat volutpat. Pellentesque augue
            neque,
          </p>
        </Card>
      </FlexGridCell>
      <FlexGridCell>
        <Card>
          <p>
            11. Aenean sed tincidunt nisl, quis fringilla leo. Nam ut lectus in
            sapien
          </p>
        </Card>
      </FlexGridCell>
      <FlexGridCell>
        <Card>
          <p>
            12. Aenean sed tincidunt nisl, quis fringilla leo. Nam ut lectus in
            sapien ultricies ultrices. Aliquam erat volutpat. Pellentesque augue
            neque, pulvinar nec Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Etiam feugiat dapibus enim, ac scelerisque sapien
            volutpat id. Curabitur at elementum nisl, in viverra enim. Phasellus
            sed ante eu turpis imperdiet auctor pretium eu nisl. Integer ut
            iaculis sapien. Ut auctor lacus mauris, nec eleifend purus elementum
            eget. Fusce vel egestas lectus. Aenean sed tincidunt nisl,
          </p>
        </Card>
      </FlexGridCell>
      <FlexGridCell>
        <Card>
          <p>
            13. Aenean sed tincidunt nisl, quis fringilla leo. Nam ut lectus in
            sapien ultricies ultrices.
          </p>
        </Card>
      </FlexGridCell>
    </FlexGrid>
  )
}

export default GridPage
